import React from 'react'

const MAP = () => {
  return (
    <>
    <section id="pricing" className="pricing">

<div className="container aos-init aos-animate" data-aos="fade-up">

  <header className="section-header">
   
  </header>

  <div className="row gy-4 aos-init aos-animate" data-aos="fade-left">

    <div className="col-lg-6 col-md-6 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
      <div className="box">
       
       
        <div className="info-box">
          <i className="bi bi-geo-alt"></i>
          <h3>Address</h3>
          <p>
            Sitapur Road, P.O-Maharishi Vidya Mandir, Lucknow-226013 (UP)</p>
        </div>
        <div className="row">
          <div className="col-md-6"> <div className="info-box">
            <i className="bi bi-telephone"></i>
            <h3>Admission Helpline</h3>
            <p>+91-80689 72233</p>
          </div></div>
          <div className="col-md-6"> <div className="info-box">
            <i className="bi bi-envelope"></i>
            <h3>Email Us</h3>
            <p>admissions@muit.in</p>
          </div></div>
          </div>


       
       

        
        <iframe className='mhidden' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14228.62351163142!2d80.9273117!3d26.9302724!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399956f63b4f2327%3A0xb820bdfd92601785!2sMaharishi%20University%20of%20Information%20Technology.!5e0!3m2!1sen!2sin!4v1703757873631!5m2!1sen!2sin" width="100%" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


      </div>
    </div>

    <div className="col-lg-6 col-md-6 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
      <div className="box">
       
       
        <div className="info-box">
          <i className="bi bi-geo-alt"></i>
          <h3>Address</h3>
          <p>Maharishi Nagar, Opp. Sec 110, PO- Maharishi Nagar, Noida-201304 (UP)</p>
        </div>
        <div className="row">
          <div className="col-md-6"> <div className="info-box">
            <i className="bi bi-telephone"></i>
            <h3>Admission Helpline</h3>
            <p>+91 11 66763870</p>
          </div></div>
          <div className="col-md-6"><div className="info-box">
            <i className="bi bi-envelope"></i>
            <h3>Email Us</h3>
            <p>admissions.noida@muit.in</p>
          </div></div>
        </div>
       
        
        <iframe className='mhidden' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.187865607848!2d77.38121877522237!3d28.534072438545536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce8ba85555525%3A0x7bb4aa0809ceeddd!2sMaharishi%20University%20of%20Information%20Technology%20Noida!5e0!3m2!1sen!2sin!4v1703758965605!5m2!1sen!2sin" width="100%" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>



   

  </div>

</div>

</section>
    </>
  )
}

export default MAP