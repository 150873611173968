import React from "react";

const Banner = () => {
  return (
    <>
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4" align="centered">
              <a href="https://muit.in/" className="logo align-items-center">
                <img src="assets/img/MUIT-LOGO.png" className="img-fluid" />
              </a>
            </div>
            <div className="col-lg-4"></div>
          </div>

          <div className="row gy-4">
            <div className="col-lg-1"></div>
            <div
              className="col-lg-2 hero-img aos-init aos-animate"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img src="assets/img/gurudev.png" className="img-fluid" />
            </div>
            <div className="col-lg-8 d-flex flex-column justify-content-center">
              <p>&nbsp;</p>
              <p style={{textAlign: 'justify', fontStyle: 'italic'}}>
                "The purpose of education is to culture the mind of a man so
                that he can accomplish all his aims in life. Education, to
                justify itself, should enable a man to use the full potential of
                his body, mind and spirit. It should also develop in him the
                ability to make the best use of his personality, surroundings
                and circumstances so that he may accomplish the maximum in life
                for himself and for others.
                <br />
                <b style={{color:'#5c020c'}}>
                  -His Holiness Maharishi Mahesh Yogi "
                </b>
              </p>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
